.all-know-more-button {
  font-family: "Flama Condensed";
}
.know-more-button {
  background-color: #ffb800;
  color: #fff;
  width: 438.35px;
  height: 61px;
  border-radius: 1076.72px;
  font-size: 34px;
  font-weight: 700;
}
.all-workshop-text {
  width: 100%;
  height: 157.21px;
  font-family: "Flama Condensed";
}
.w-top-text {
  font-size: 96px;
  font-weight: 900;
  color: #ffb800;
}
.w-bottom-text {
  margin-top: -22px;
  font-size: 45px;
  font-weight: 900;
}
@media screen and (max-width:991px) {
  .all-know-more-button {
    font-family: "Flama Condensed";
  }
  .know-more-button {
    background-color: #ffb800;
    color: #fff;
    width: 50%;
    height: 25px;
    border-radius: 1076.72px;
    font-size: 16px;
    font-weight: 700;
  }

  .all-workshop-text {
    width: 100%;
    height: auto;
    font-family: "Flama Condensed";
  }
  .w-top-text {
    font-size: 44px;
    font-weight: 900;
    color: #ffb800;
  }
  .w-bottom-text {
    margin-top: -7px;
    font-size: 21px;
    font-weight: 900;
  }
}