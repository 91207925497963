.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 68px;
  padding-bottom: 34px;
}

.checkboxContainer {
  padding-bottom: 34px;
}
.checkbox {
  display: flex;
  justify-content: center;
  margin: auto;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin: auto;
}
.back-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
  font-family: "Flama Condensed";
  font-size: 20px;
  font-weight: 400;
  color: #6d7e91;
  cursor: pointer;
}
@media screen and (max-width:991px) {
  .form{
    width: 90%;
    margin: 0 auto;
  }
  .checkbox{
    justify-content: left;
  }
}