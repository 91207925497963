.all-sixth-component {
  width: 100%;
  display: flex;
  justify-content: right;
  font-family: "Flama Condensed";
  margin-top: -1px;
  margin-bottom: -6px;
}
.sixc-left {
  width: 392px;
}
.sixc-right {
  width: 1048;
}
.sixc-content {
  width: 489px;
  position: absolute;
  left: 8%;
  margin-top: 75px;
}
.sixc-first-text {
  font-size: 32px;
  font-weight: 700;
  color: #738392;
}
.sixc-second-text {
  margin-top: 15px;
}
.sixc-third-text {
  font-size: 40px;
  font-weight: 700;
  color: #738392;
  text-align: center;
  margin-top: 55px;
}
.sixc-button {
  margin-top: 52px;
}
@media screen and (max-width: 991px) {
  .all-six-mob {
    width: 100%;
    height: 345px;
    display: flex;
    justify-content: right;
  }
  .six-mob-left {
    background: linear-gradient(to right, white 0%, white 70%, rgba(255,255,255,0) 100%);;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0%;
    height: 345px;
  }
  .six-mob-text1 {
    font-family: "Flama Condensed";
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #738392;
    margin-top: 50px;
    margin-right: 25%;
  }
  .six-mob-text2 {
    margin-top: 10px;
    margin-right: 25%;
  }
  .six-mob-text3 {
    width: 50%;
    font-family: "Flama Condensed";
    font-size: 15px;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
    color: #708090;
    margin-left: 12%;
  }
.sixc-button{
    margin-right: 25%;
}
}
