.level-bar-details {
  text-align: center;
  justify-content: center;
  display: block;
}
.level-bar-title-details {
  font-family: "Flama Condensed";
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom: 10px;
}

.all-bar-details {
  width: 280px;
  height: 49px;
  background-color: #d3d6d8;
  display: flex;
  margin: 0 auto;
}
.background-details {
  height: 49px;
}
.front-details {
  z-index: 1;
  position: absolute;
  border: 1px solid #fff;
}
