.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #6d7e91;
  font-family: "Flama Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: auto;
  margin-bottom: 20px;
}

.field-input {
  border-radius: 10px;
  border: 1px solid #738392;
  min-width: 466px;
  height: 55px;
  margin-top: 12px;
  font-size: 20px;
  color: #6d7e91;
  padding-left: 10px;
}

.field-input-invalid {
  border-radius: 10px;
  border: 1px solid #738392;
  min-width: 466px;
  height: 55px;
  margin-top: 12px;
  font-size: 20px;
  color: #6d7e91;
  padding-left: 10px;
  border-color: red;
}

.error {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}
@media screen and (max-width:991px) {
  .field-mob {
    width: 89%;
    height: 94px;
   margin: 0 auto;
   font-family: "Flama Condensed";
  }
  .field-mob-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #6d7e91;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .mob-field {
    width: 87%;
    height: 40px;
    border: 1px solid #738392;
    border-radius: 10px;
    font-size: 20px;
    color: #738392;
    margin-left: -7%;
  }
  .mob-field-invalid {
    width: 87%;
    height: 40px;
    border: 1px solid #738392;
    border-radius: 10px;
    font-size: 20px;
    color: #738392;
    margin-left: -7%;
    border-color: red;
  }
  .mob-error{
    color: red;
    font-size: 16px;
    text-align: right;
  }
}