.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  background: #2d2a26;
  color: #fff;
  text-align: center;
}

.navbar-title {
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.pipe {
  color: #d9d9d9;
  font-size: 34px;
  font-weight: 300;
  /* margin-top: 5px; */
  margin-left: 25px;
  margin-right: 22px;
  margin-top: auto;
  margin-bottom: auto;
}

.navbar-text {
  margin: auto;
}

nav {
  width: 25%;
}

.navbar-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navbar-options a {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-options a:hover {
  color: #d9d9d9;
}
@media screen and (max-width: 991px) {
  .navbar-mob {
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: right;
    background: #2d2a26;
    color: #fff;
    gap: 5px;
  }
  .navbar-center {
    display: flex;
    gap: 5px;
    height: 100%;
    margin: auto;
  }
  .navbar-logo {
    margin-top: 9px;
  }
  .pipe-mob {
    background-color: #d9d9d9;
    width: 1.5px;
    height: 60%;
    margin: auto;
  }
  .navbar-mob-title {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    margin: auto;
  }
}
.navbar-menu {
  margin: auto;
}
.dropdown-menu {
  width: 100%;
  height: 34px;
  display: none;
  text-decoration: none;
  background-color: #47433d;
  justify-content: center;
}
.dropdown-menu a {
  text-decoration: none;
  margin: auto;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter";
}
.show-dropdown-menu {
  display: flex;
}
