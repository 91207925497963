.button-download {
    width: 309px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .download-img{
    margin-right: 7px;
  }
  .download-button-text{
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    
  }
  @media screen and (max-width:991px){
    .button-download {
      width: 85%;
      height: 35px;
    }
    .download-button-text{
      font-family: "Flama Condensed";
      font-size: 19px;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: left;
      
    }
  }