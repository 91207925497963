.top-results {
  display: flex;
}
.level-bar-result{
  margin-top: 40px;
  margin-left: 25px;
}
.bottom-results {
  display: flex;
  justify-content: center;
  align-items: center;
}
.results {
  margin-bottom: 100px;
  width: 698px;
  height: 238px;
}
@media screen and (max-width:991px) {
  .results {
    margin-bottom: 1%;
    width: 100%;
    height: auto;
  }
  .top-results {
    display: flex;
    justify-content: center;
  }
  .level-bar-result{
    margin-top: 15px;
    margin-left: 10px;
  }
  .bottom-results {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}