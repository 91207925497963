.all-ebook {
  width: 99px;
  position: fixed;
  display: block;
  bottom: 2%;
  right: 2%;
  top: 77%;
  left: 90%;
  text-decoration: none;
  cursor: pointer;
}
.eb-circle {
  background-color: #ffb800;
  width: 100%;
  border-radius: 50%;
  height: 99px;
}
.eb-img {
  padding-top: 10px;
}
.eb-text {
  font-family: "Flama Condensed";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  color: #ffb800;
  width: 67px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .all-ebook {
    display: none;
  }
}
