.level-bar {
  text-align: left;
}
.level-bar-title{
  font-family: "Flama Condensed";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
}
.all-bar{
  width: 337px;
  height:59px;
  background-color: #D3D6D8;
  display: flex;
  margin-top: 20px;
}
.background {
  height: 59px;
}
.front{
  z-index: 1;
  position: absolute;
  border: 2px solid #fff;
}
@media screen and (max-width: 991px) {
  .level-bar {
    text-align: left;
  }
  .level-bar-title{
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .all-bar{
    width: 170px;
    height:29px;
    background-color: #D3D6D8;
    display: flex;
    margin-top: 10px;
    overflow: hidden;
  }
  .background {
    height: 29px;
  }
  .front{
    z-index: 1;
    position: absolute;
    border: 0px solid #fff;
    margin-top: -15px;
  }
  @media screen and (max-width:410px) {
    .all-bar{
      width: 120px;
      height:19px;
      background-color: #D3D6D8;
      display: flex;
      margin-top: 10px;
      overflow: hidden;
    }
    .background {
      height: 29px;
    }
    .front{
      z-index: 1;
      position: absolute;
      border: 0px solid #fff;
      margin-top: -20px;
    }
  }
}
