.all-first {
  display: flex;
  justify-content: right;
  width: 100%;
  height: 650px;
  background: #738392;
  font-family: "Flama Condensed";
}
.first-left {
  width: 50vw;
  margin-top: 80px;
}
.first-text1 {
  font-weight: 900;
  font-size: 40px;
  color: #fff;
  width: 451px;
  margin: 0 auto;
  text-align: left;
}
.first-text2 {
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  width: 451px;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
}
.first-text3 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  width: 451px;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
}
.first-pillars {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 40px;
}
.first-right {
  width: 50vw;
  background: url(./assets/backImage.svg) no-repeat;
  background-size: cover;
}
.first-text4 {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #fff;
  width: 476px;
  margin: 0 auto;
  margin-top: 90px;
}
.first-video {
  height: 314px;
  margin: 0 auto;
  margin-top: 20px;
}
.first-button {
  margin-top: 35px;
}

@media screen and (max-width: 991px) {
  .all-first {
    display: inline-block;
    justify-content: center;
    width: 100%;
    height: auto;
    background: #738392;
    font-family: "Flama Condensed";
  }
  .first-left {
    width: 100%;
    margin-top: 50px;
  }
  .first-text1 {
    font-size: 34px;
    width: 100%;
    text-align: center;
  }
  .first-text2 {
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .first-text3 {
    font-size: 14px;
    width: 98%;
    text-align: center;
    margin-top: 20px;
  }

  .first-pillars {
    display: flex;
    justify-content: center;
    gap: 10%;
    margin-top: 40px;
  }

  .first-right {
    width: 100%;
    background: #738392;
  }
  .first-text4 {
    font-size: 14px;
    width: 85%;
    margin-top: 5px;
  }
  .first-video {
    height: 192px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .first-button {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
