.foc-title{
    font-family: "Flama Condensed";
    font-size: 40px;
    font-weight: 700;
    color:#6d7e91;
    margin-top: 60px;
}
.foc-steps{
    display: flex;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 80px;
}
.step-2{
    margin-left: 23px;
}
.step-3{
    margin-left: 23px;
}
@media screen and (max-width:991px){
    .foc-title{
        font-size: 25px;
    }
    .foc-steps{
        display: inline-block;
    }
    .step-1{
        margin: 0 auto;
    }
    .step-2{
        margin: 0 auto;
        padding-top: 60px;
    }
    .step-3{
        margin: 0 auto;
        padding-top: 60px;
    }
}