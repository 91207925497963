.all-pillars{
    height:114px;
}

.pillar-name{
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    height: 29px;
}
@media screen and (max-width: 991px){
    .pillar-name{
        font-size: 13.50px;
    }
}