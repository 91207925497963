.question-option {
  width: 100%;
  display: flex;
  justify-content: center;
}
.border {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border-radius: 15px;
  width: 98%;
  margin-bottom: 10px;
}
.border.checked {
  background-color: rgba(195, 221, 236, 0.904);
}

.button {
  width: 40px;
  height: 40px;
  margin-top: 8px;
  margin-left: 20px;
}

.question-option-input {
  display: inline-block;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  background-color: #fff;
  cursor: pointer;
}
.question-option-input:checked {
  background: url("./assets/checked.svg") no-repeat;
  background-color: #326582;
  border-color: #326582;
  background-position: center;
}

.question-option-text {
  color: #000;
  font-family: "Karbid Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 1090px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media screen and (max-width:991px) {
  .question-option-text {
    font-size: 14px;
    width: 99%;
  }
  .button {
    width: 40px;
    height: 40px;
    margin-top: 4px;
    margin-left: 10px;
  }
}