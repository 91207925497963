.allCheckbox{
  font-family: "Flama Condensed";
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}
.checkboxComponent {
  display: flex;
  align-items: flex-start;
 
}
.text{
  width: 434px;
  text-align: left;
  margin-left: 7px;
}
.hiddenCheckbox {
  display: none;
}

.customCheckbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #326582;
  border-radius: 8px;
  margin-right: 10px;
}

.hiddenCheckbox:checked + .customCheckbox {
  background: #326582;
  position: relative;
}

.hiddenCheckbox:checked + .customCheckbox:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.error{
  margin-top: -5px;
  text-align: right;
}
@media screen and (max-width:991px){
  .allCheckbox{
    font-size: 14px;
    margin-left: 8%;
  }
  .text{
    width: 80%;
    text-align: left;
    margin-left: 7px;
  }
  .error{
    margin-top: -5px;
    text-align: center;
  }
}