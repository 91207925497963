@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Thin.eot");
  src: local("Flama Condensed Thin"), local("FlamaCondensed-Thin"),
    url("./assets/fonts/FlamaCondensed-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Thin.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Thin.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Black.eot");
  src: local("Flama Condensed Black"), local("FlamaCondensed-Black"),
    url("./assets/fonts/FlamaCondensed-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Black.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Black.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed Book";
  src: url("./assets/fonts/FlamaCondensed-Book.eot");
  src: local("Flama Condensed Book"), local("FlamaCondensed-Book"),
    url("./assets/fonts/FlamaCondensed-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Book.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Book.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Book.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Light.eot");
  src: local("Flama Condensed Light"), local("FlamaCondensed-Light"),
    url("./assets/fonts/FlamaCondensed-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Light.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Light.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Extrabold.eot");
  src: local("Flama Condensed Extrabold"), local("FlamaCondensed-Extrabold"),
    url("./assets/fonts/FlamaCondensed-Extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Extrabold.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Extrabold.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Extrabold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Ultralight.eot");
  src: local("Flama Condensed Ultralight"), local("FlamaCondensed-Ultralight"),
    url("./assets/fonts/FlamaCondensed-Ultralight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Ultralight.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Ultralight.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Ultralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Bold.eot");
  src: local("Flama Condensed Bold"), local("FlamaCondensed-Bold"),
    url("./assets/fonts/FlamaCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Bold.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Bold.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed Basic";
  src: url("./assets/fonts/FlamaCondensed-Basic.eot");
  src: local("Flama Condensed Basic"), local("FlamaCondensed-Basic"),
    url("./assets/fonts/FlamaCondensed-Basic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Basic.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Basic.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Basic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Medium.eot");
  src: local("Flama Condensed Medium"), local("FlamaCondensed-Medium"),
    url("./assets/fonts/FlamaCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Medium.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Medium.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flama Condensed";
  src: url("./assets/fonts/FlamaCondensed-Semibold.eot");
  src: local("Flama Condensed Semibold"), local("FlamaCondensed-Semibold"),
    url("./assets/fonts/FlamaCondensed-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/FlamaCondensed-Semibold.woff2") format("woff2"),
    url("./assets/fonts/FlamaCondensed-Semibold.woff") format("woff"),
    url("./assets/fonts/FlamaCondensed-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

@font-face {
  font-family: "Karbid Pro";
  src: url("./assets/fonts/Karbid\ Pro.otf") format("opentype");
}
