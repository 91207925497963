.form-ws {
  margin-top: 120px;
  font-family: "Flama Condensed";
  color: #3f1c6d;
}
.form-ws-title {
  font-size: 40px;
  font-weight: 700;
}
.fields {
  margin-top: 60px;
}
.ws-send-button {
  width: 438.35px;
  height: 61px;
  border-radius: 1076, 72px;
  background-color: #ffb800;
  color: #fff;
  font-family: "Flama Condensed";
  font-size: 34px;
  font-weight: 700;
  margin-top: 25px;
}

.back-button-ws {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
  font-family: "Flama Condensed";
  font-size: 24px;
  font-weight: 400;
  color: #6d7e91;
  cursor: pointer;
}
@media screen and (max-width:991px){
  .form-ws {
    margin-top: 30px;
    font-family: "Flama Condensed";
    color: #3f1c6d;
  }
  .form-ws-title {
    font-size: 25px;
    font-weight: 700;
  }
  .fields {
    margin-top: 60px;
  }
  .ws-send-button {
    width: 90%;
    height: 48px;
    border-radius: 1076, 72px;
    background-color: #ffb800;
    color: #fff;
    font-family: "Flama Condensed";
    font-size: 25px;
    font-weight: 700;
    margin-top: 25px;
  }
  
  .back-button-ws {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 50px;
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 400;
    color: #6d7e91;
    cursor: pointer;
  }
}
