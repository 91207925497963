.resume {
  font-weight: 600;
  margin-top: 25px;
  margin-right: 1.6rem;
}
.all-resume {
  font-family: "Flama Condensed";
  width: 450px;
  font-size: 32px;
  text-align: left;
  color: #738392;
  margin-left: 45px;
}
.resume-title {
  font-weight: 700;
  margin-top: 70px;
  margin-right: 1.6rem;
}

.all-graph {
  margin-left: 65px;
  margin-top: -20px;
}
.titles {
  color: #738392;
  font-family: "Flama Condensed";
  font-weight: 700;
  font-size: 16px;
}

.top-inovation-teams {
  margin-top: -400px;
  display: flex;
  justify-content: center;
}
.inovation-teams {
  margin-top: 10px;
}

.left-pioneering {
  display: flex;
  margin-top: 235px;
  margin-left: 70px;
}
.pioneering {
  margin-top: 10px;
}

.right-open-minded {
  display: flex;
  justify-content: right;
  margin-top: -30px;
  margin-right: 50px;
}
.open-minded {
  margin-top: 10px;
}
@media screen and (max-width: 1219px) {
  .all-graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: -20px;
    width: 100%;
  }
  .titles {
    color: #738392;
    font-family: "Flama Condensed";
    font-weight: 700;
    font-size: 16px;
    display: flex;
    position: absolute;
    justify-content: center;
    margin-top: -355px;
    height: 235px;
    width: 100%;
    gap: 50px;
  }

  .top-inovation-teams {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .inovation-teams {
    margin-top: 0px;
  }

  .left-pioneering {
    display: flex;
    margin-top: 220px;
    margin-left: 0px;
  }
  .pioneering {
    margin-top: 0px;
  }

  .right-open-minded {
    display: flex;
    justify-content: right;
    margin-top: 220px;
    margin-right: 0px;
  }
  .open-minded {
    margin-top: 0px;
  }

  .resume {
    font-weight: 600;
    padding-top: 25px;
    width: 85%;
    margin: 0 auto;
  }
  .all-resume {
    font-family: "Flama Condensed";
    width: 100%;
    font-size: 32px;
    text-align: left;
    color: #738392;
    margin-top: -50px;
    margin-left: 0px;
  }
  .resume-title {
    font-weight: 700;
    margin-top: 0px;
    width: 85%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 991px) {
  .all-graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: -20px;
    width: 95%;
  }
  .titles {
    color: #738392;
    font-family: "Flama Condensed";
    font-weight: 700;
    font-size: 12px;
    display: flex;
    position: absolute;
    justify-content: center;
    margin-top: -355px;
    height: 235px;
    width: 100%;
    gap: 50px;
  }

  .top-inovation-teams {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .inovation-teams {
    margin-top: 0px;
  }

  .left-pioneering {
    display: flex;
    margin-top: 220px;
    margin-left: 0px;
  }
  .pioneering {
    margin-top: 0px;
  }

  .right-open-minded {
    display: flex;
    justify-content: right;
    margin-top: 220px;
    margin-right: 0px;
  }
  .open-minded {
    margin-top: 0px;
  }

  .resume {
    font-weight: 600;
    padding-top: 25px;
    width: 80%;
    margin: 0 auto;
  }
  .all-resume {
    font-family: "Flama Condensed";
    width: 104%;
    font-size: 15px;
    text-align: left;
    color: #738392;
    margin-top: -50px;
    margin-left: 0px;
  }
  .resume-title {
    font-weight: 700;
    margin-top: 0px;
    width: 85%;
    margin: 0 auto;
  }
}
