.border-results-details {
  border: 5px solid;
  width: 948px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 45px;
}
.top-border {
  margin-bottom: -75px;
}
.border-icon {
  z-index: 1;
}
.title-border {
  font-family: "Flama Condensed";
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom: -15px;
}

.border-children {
  margin-top: 95px;
}
@media screen and (max-width:991px){
  .border-results-details {
    border: 5px solid;
    width: 90%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 45px;
  }
  .border-children {
    width: 100%;
    margin-top: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}