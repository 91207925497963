.header {
  background: rgba(115, 131, 146, 1);
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Flama Condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  position: relative;
}

.header-fingerprint {
  position: absolute;
  left: 0;
  height: 100%;
  width: auto;
}

.header-text {
  text-align: center;
  z-index: 1;
}
@media screen and (max-width:991px) {
  .header {
    background: rgba(115, 131, 146, 1);
    height: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Flama Condensed";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    position: unset;
  }
  
  .header-fingerprint {
    position:unset;
    left: 10%;
    height: 65%;
    width: auto;
    margin-left: 30px;
  }
  
  .header-text {
    text-align: left;
    z-index: 1;
    margin-left: 20px;
  }
  
}