.all-ep{
  width: 190px;
  height: 262.16px;
  font-family: "Flama Condensed";
  
}
.ep-name{
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
}
.ep-job-input{
  box-shadow: 2px 4px 4px 0px #00000073;
  background-color: #FFFFFF;
  border-radius: 10px;
  width:100%;
  height:38.16px;
  margin-top: 5px;
  text-align: left;
  display: flex;
  cursor: pointer;
}
.ep-job-name{
  color: #738392;
  width: 170px;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: 600;
  font-size: 19px;
}
.ep-plus-ball{
    width:24px;
    height: 24px;
    background-color: #738392;
    border-radius: 50%;
    box-shadow: 1px 1px 4px 0px #00000073;
    margin-top: 7px;
    margin-right: 5px;
}
.ep-plus{
  font-size: 30px;
  margin-top: -12px;
  margin-left: 5px;
}
.ep-persons-information{
  display: none;
}
.show-persons-information{
  position: fixed;
  display: flex;
  z-index: 2;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px){
  .show-persons-information{
    top: -60%;
    left: -7%;
  }
}