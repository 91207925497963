.pillar-header {
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.pillar-img {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.pillar-header-text {
  margin-top: 20px;
  margin-bottom: 30px;
  color: #2f4b7b;
  text-align: center;
  z-index: 1;
  font-family: "Flama Condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media screen and (max-width:991px){
  .pillar-header {
    height: 70px;
  }
  .pillar-header-text {
    font-size: 25px;
  }
}