.all-second{
    width:100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    font-family: "Flama Condensed";
}
.second-left{
    display: flex;
    justify-content: center;
    width: 50%;
    height: 483px;
    margin-top: 17px;
}
.second-right{
    display: flex;
    justify-content: center;
    width: 50%;
    height: 483px;
    margin-top: 17px;
    margin-left: 20px;
}
.second-right-mobile-disabled{
    display: none;
}
.second-left-texts{
    text-align: left;
    margin-left: 40px;
    margin-top: 40px;
}
.second-left-title{
    font-size: 60px;
    font-weight: 700;
    color: #738392;
}
.second-left-li{
    font-weight: 500;
    font-size: 20px;
    width: 332px;
    margin-top: -10px;
    color:#6d7e91;
}
.second-left li{
    padding: 10px;
}
.second-right-texts{
    text-align: left;
    margin-left: 40px;
    margin-top: 40px;
}
.second-right-title{
    font-size: 60px;
    font-weight: 700;
    color: #738392;
}
.second-right-li{
    font-weight: 500;
    font-size: 20px;
    width: 332px;
    margin-top: -10px;
    color:#6d7e91;
}
.second-right li{
    padding: 15px;
}
.second-asterisk{
    margin-left: 22px;
    margin-top: -15px;
    font-size: 18px;
    font-weight: 300;
}
@media screen and (max-width:1066px){
    .second-right-title{
        font-size: 40px;
        font-weight: 700;
        color: #738392;
    }
    .second-right-li{
        font-weight: 500;
        font-size: 18px;
        width: 210px;
        margin-top: -10px;
        color:#6d7e91;
        margin-left: -40px;
    }
    .second-left-title{
        font-size: 40px;
        font-weight: 700;
        color: #738392;
    }
    .second-left-li{
        font-weight: 500;
        font-size: 18px;
        width: 270px;
        margin-top: -10px;
        color:#6d7e91;
        margin-left: -40px;
    }
    .second-right{
        display: flex;
        justify-content: center;
        width: 50%;
        height: 483px;
        margin-top: 17px;
        margin-left: -10px;
    }
}
@media screen and (max-width: 991px){
    .all-second{
        display: inline-block;
    }
    .second-left{
        height: 220px;
        width: 100%;
        margin-top: 20px;
    }
    .second-right-mobile{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 222px;
        margin-top: 5px;
    }
    .second-right-disabled{
        display: none;
    }
    .second-left-texts{
        text-align: left;
        margin-top: 17px;
        margin-left: 30px;
    }
    .second-left-title{
        font-size: 18px;
        font-weight: 700;
        color: #738392;
    }
    .second-left-li{
        font-weight: 500;
        font-size: 15px;
        width: 270px;
        margin-top: -10px;
        color:#6d7e91;
        margin-left: -40px;
    }
    .second-left li{
        padding: 1px;
    }
    .second-right-texts{
        text-align: left;
    }
    .second-right-title{
        font-size: 18px;
        font-weight: 700;
        color: #738392;
    }
    .second-right-li{
        font-weight: 500;
        font-size: 15px;
        width: 210px;
        margin-top: -10px;
        color:#6d7e91;
        margin-left: -40px;
    }
    .second-right li{
        padding: 10px;
    }
    .second-asterisk{
        margin-left: 22px;
        margin-top: -5px;
        font-size: 15px;
        font-weight: 300;
    }
}
