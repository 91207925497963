.pdf-generation {
  width: 1700px !important;
}
.overview {
  font-family: "Flama Condensed";
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0em;
  color: #738392;
  margin-top: 90px;
  margin-bottom: 100px;
}
.general-results {
  display: flex;
  justify-content: center;
  height: 1100px;
  margin-top: -50px;
}
.graph {
  margin-top: 90px;
  margin-left: -50px;
}
.results-from-page {
  padding-bottom: 20px;
  margin-left: 20px;
}
.line {
  height: 2px;
  border: none;
  background-image: linear-gradient(to right, #f8b127, #9bbacc);
  justify-content: center;
  width: 459px;
}
.detailed-view {
  font-family: "Flama Condensed";
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0em;
  color: #738392;
  padding-top: 50px;
  padding-bottom: 50px;
}
.workshop-results {
  margin-top: 50px;
}
@media screen and (max-width: 1219px) {
  .graph {
    margin-top: 40px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .overview {
    font-family: "Flama Condensed";
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #738392;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .general-results {
    display: inline-block;
    margin-top: 0px;
    width: 100%;
    height: auto;
  }
  .results-from-page {
    width: 100%;
    padding-bottom: 20px;
    margin-left: 0px;
  }
  .results-from-page-results {
    display: flex;
    justify-content: center;
  }
  .line {
    height: 2px;
    border: none;
    background-image: linear-gradient(to right, #f8b127, #9bbacc);
    justify-content: center;
    width: 50%;
  }
  .detailed-view {
    font-family: "Flama Condensed";
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #738392;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
