.all-field-ws {
  width: 466px;
  height: 94px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.field-ws-title {
  font-family: "Flama Condensed";
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #3f1c6d;
  margin-bottom: 10px;
}
.ws-field {
  width: 100%;
  height: 55px;
  border: 1px solid #3f1c6d;
  border-radius: 10px;
  font-size: 20px;
  color: #3f1c6d;
  padding-left: 10px;
}
.ws-field-invalid {
  width: 100%;
  height: 55px;
  border: 1px solid #3f1c6d;
  border-radius: 10px;
  font-size: 20px;
  color: #3f1c6d;
  padding-left: 10px;
  border-color: red;
}
.ws-error{
  color: red;
  font-size: 16px;
  text-align: right;
}
@media screen and (max-width:991px) {
  .all-field-ws {
    width: 87%;
    height: 94px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .field-ws-title {
    font-family: "Flama Condensed";
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #3f1c6d;
    margin-bottom: 10px;
  }
  .ws-field {
    width: 100%;
    height: 40px;
    border: 1px solid #3f1c6d;
    border-radius: 10px;
    font-size: 20px;
    color: #3f1c6d;
    padding-left: 10px;
  }
  .ws-field-invalid {
    width: 100%;
    height: 55px;
    border: 1px solid #3f1c6d;
    border-radius: 10px;
    font-size: 20px;
    color: #3f1c6d;
    padding-left: 10px;
    border-color: red;
  }
  .ws-error{
    color: red;
    font-size: 16px;
    text-align: right;
  }
}
