.button-diagnosis {
    width: 309px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 759px;
    background: #fff;
    border:none;
    color: #6d7e91;
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
  }