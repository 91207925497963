.full-content {
  width: 677px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0em;
  font-family: "Flama Condensed";
  border-radius: 19px;
  border: 2px solid;
}
.content {
  font-weight: 600;
  font-size: 19px;
  padding: 20px 10px 20px 20px;
}
.title-content {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  padding: 5px;
  border-radius: 14px 14px 0 0;
}
@media screen and (max-width:991px) {
  .full-content {
    width: 80%;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0em;
    font-family: "Flama Condensed";
    border-radius: 19px;
    border: 2px solid;
  }
  .content {
    font-weight: 600;
    font-size: 15px;
    padding: 20px 10px 20px 20px;
  }
  .title-content {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    padding: 5px;
    border-radius: 14px 14px 0 0;
  }
}