.top-result-details {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.result-details {
  margin-bottom: 50px;
}
@media screen and (max-width:991px){
  .bottom-result-details{
    display: flex;
    justify-content: center;
  }
}
