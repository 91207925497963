.allCargos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #6d7e91;
  font-family: "Flama Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: auto;
  margin-bottom: 20px;
}

.dropdown::-webkit-scrollbar {
  background-color: #8a98a8;
  width: 45px;
  border-radius: 10px;
}

.dropdown::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 9999px;
  border: 18px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: #5c6a7a;
  border-radius: 9999px;
  height: 100px;
  border: 18px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.value {
  font-family: "Flama Condensed";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0em;
  color: #6d7e91;
  margin-top: 10px;
}
.job-input {
  border-radius: 10px;
  border: 1px solid #738392;
  width: 480px;
  height: 60px;
  margin-top: 12px;
  font-size: 20px;
  color: #6d7e91;
  padding-left: 10px;
  background: url("./assets/arrowDown.svg") no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
  text-align: left;
}
.job-input-selected {
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #738392;
  width: 480px;
  height: 60px;
  margin-top: 12px;
  font-size: 20px;
  color: #6d7e91;
  padding-left: 10px;
  background: url("./assets/arrowUp.svg") no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
}

.job-input-invalid {
  border-radius: 10px;
  border: 1px solid #738392;
  width: 480px;
  height: 60px;
  margin-top: 12px;
  font-size: 20px;
  color: #6d7e91;
  padding-left: 10px;
  border-color: red;
}
.dropdown {
  width: 100%;
  background-color: #8a98a8;
  height: 171px;
  overflow: auto;
  border-radius: 0 0 10px 10px;
  display: none;
}
.dropdown.show-dropdown {
  display: block;
}
.list-dropdown {
  font-family: "Flama Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  width: 100%;
}
.options {
  color: #fff;
  padding-top: 5px;
  cursor: pointer;
  text-align: left;
  padding-left: 16px;
  
}
.options:hover {
  background-color: #6d7e91;
}
.error {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}
@media screen and (max-width:991px) {
  .allCargos-mob {
    width:89%;
    height: 94px;
   margin: 0 auto;
   font-family: "Flama Condensed";
  }
  .cargos-mob{
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #6d7e91;
    margin-left: 10px;
  }
  .dropdown-mob::-webkit-scrollbar {
    background-color: #8a98a8;
    width: 45px;
    border-radius: 10px 0px 10px 0px;
  }
  
  .dropdown-mob::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 9999px;
    border: 18px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  
  .dropdown-mob::-webkit-scrollbar-thumb {
    background-color: #5c6a7a;
    border-radius: 9999px;
    height: 100px;
    border: 18px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  
  .value-mob {
    font-family: "Flama Condensed";
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0em;
    color: #6d7e91;
    margin-top: 7px;
  }
  .job-input-mob {
    border-radius: 10px;
    border: 1px solid #738392;
    width: 87%;
    height: 42px;
    margin-left: 2%;
    margin-top: 10px;
    font-size: 20px;
    color: #6d7e91;
    padding-left: 10px;
    background: url("./assets/arrowDown.svg") no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    text-align: left;
  }
  .job-input-selected-mob {
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #738392;
    width: 85%;
    height: 42px;
    margin-top: 10px;
    font-size: 20px;
    color: #6d7e91;
    padding-left: 10px;
    background: url("./assets/arrowUp.svg") no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
  }
  
  .job-input-invalid-mob {
    border-radius: 10px;
    border: 1px solid #738392;
    width: 85%;
    height: 42px;
    margin-top: 12px;
    font-size: 20px;
    color: #6d7e91;
    padding-left: 10px;
    border-color: red;
  }
  .dropdown-mob {
    width: 70.7%;
    background-color: #8a98a8;
    height: 171px;
    overflow: auto;
    border-radius: 0 0 10px 10px;
    display: none;
    z-index: 3;
    margin-left: 10px;
    position: absolute;
  }
  .show-dropdown-mob {
    display: block; 
    z-index: 4;
  }
  .list-dropdown-mob {
    font-family: "Flama Condensed";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    width: 100%;
    z-index: 4;
  }
  .options-mob {
    color: #fff;
    padding-top: 5px;
    cursor: pointer;
    text-align: left;
    padding-left: 16px;
    z-index: 4;
  }
  .options-mob:hover {
    background-color: #6d7e91;
    z-index: 4;
  }
  .error-mob {
    color: red;
    font-size: 16px;
    text-align: right;
  }
}