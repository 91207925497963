.all-hwp-pillars{
    font-family: "Flama Condensed";
    width: 307px;
}

.hwp-pillars-title{
    font-weight: 700;
    font-size: 40px;
}
.hwp-pillars-subtitle{
    font-weight: 600;
    font-size: 24px;
    margin-top: 20px;
}
.hwp-pillars-text{
    font-weight: 400;
    font-size: 24px;
    margin-top: 20px;
}
@media screen and (max-width:991px) {
    .all-hwp-pillars{
        width: 100%;
        margin: 0 auto;
    }
    .hwp-pillars-subtitle{
        font-weight: 600;
        font-size: 24px;
        padding-top: 20px;
        width: 80%;
        margin: 0 auto;
    }
    .hwp-pillars-text{
        font-weight: 400;
        font-size: 24px;
        padding-top: 20px;
        width: 75%;
        margin: 0 auto;
        padding-bottom: 50px;
    }
}