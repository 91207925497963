.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  background: #2d2a26;
  text-align: center;
  font-family: "Inter";
}
.redes {
  display: flex;
  gap: 3rem;
}

.contato a {
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  line-height: normal;
  text-decoration: none;
}
.endereco {
  text-align: left;
  color: #fff;
}
.footer-mobile {
  display: none;
}
@media screen and (max-width: 991px) {
  .footer {
    display: none;
  }
  .footer-mobile {
    display: inline-block;
    height: 107px;
    width: 100%;
    background: #2d2a26;
    font-family: "Inter";
  }
  .top-footer {
    display: flex;
    justify-content: center;
    gap: 43px;
    margin-top: 20px;
  }
  .bottom-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 13px;
  }
  .redes {
    display: flex;
    gap: 25px;
  }

  .contato a {
    font-size: 12px;
  }
  .endereco {
    font-size: 12px;
  }
}
