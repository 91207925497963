.all-top-ws {
  height: 707px;
  background-color: #3f1c6d;
  display: flex;
  justify-content: right;
  font-family: "Flama Condensed";
}
.left-top-ws {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  color: #fff;
  z-index: 1;
  position: absolute;
  left: 5%;
}
.top-ws-title {
  margin-left: 12px;
  margin-top: 40px;
}
.button-contact-ws {
  margin-top: 20px;
}
@media screen and (max-width:369px) {
  .ws-mob-left{
    font-size: 4vw !important;
  }
}
@media screen and (max-width: 991px) {
  .all-top-wsmob {
    width: 100%;
    height: 412px;
    display: flex;
    justify-content: right;
    font-family: "Flama Condensed";
  }
  .ws-mob-left {
    background: linear-gradient(
      to right,
      #3f1c6d 0%,
      #3f1c6d 70%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0%;
    height: 412px;
    font-size:15px;
    font-weight: 300;
    text-align: left;
    color: #fff;
  }
  .top-ws-mob-content{
    margin-left: 20px;
    margin-top: 20px;
  }
  .top-wsmob-title{
    margin-top: 20px;
    margin-left: 5px;
  }
  .top-wsmob-topics{
    margin-top: 5px;
    width: 68%;
    height: 90%;
  }
}
