.progress-bar-background {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0px;
  margin: auto;
  width: 100%;
  height: 20px;
  z-index: 9998;
  border-radius: 0px 0px 0px 0px;
  background: #d9d9d9;
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  z-index: 9998;
  padding: 10px;
  border-radius: 0px 38px 38px 0px;
  color: #000;
  text-align: center;
  font-family: "Flama Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.progress-bar-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding: 10px;
  border-radius: 0px 38px 38px 0px;
  color: #000;
  text-align: center;
  font-family: "Flama Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
