.all-tc {
  background: url(./assets/backgroundImage.svg) no-repeat;
  height: 708.06px;
  background-color: #738392;
  font-family: "Flama Condensed";
}
.tc-top-text-title {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #fff;
  padding-top: 65px;
  padding-bottom: 35px;
}
.tc-board {
  background-color: #fff;
  width: 85%;
  height: 410px;
  border-radius: 20px;
  margin: 0 auto 52px;
  display: flex;
}
.tc-board-mobile-disabled{
  display: none;

}
.tc-geometry {
  z-index: 1;
  margin-top: -77px;
  margin-left: -68px;
}
.tc-all-content{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width:90%
}
.tc-all-left {
  width: 412px;
  height: 308px;
  margin: 0 auto;
}
.tc-all-right {
  width: 303px;
  height: 308px;
  margin: 0 auto;
  padding-top: 23px;
}
.tc-left-title {
  font-weight: 700;
  font-size: 47.31px;
  color: #f46a63;
  margin-top: 5px;
}
.tc-left-text {
  font-weight: 600;
  font-size: 20px;
  color: #6c6b75;
  margin-top: 10px;
}
.tc-right-title {
  font-weight: 700;
  font-size: 75.7px;
  color: #f46a63;
}
.tc-right-text {
  font-weight: 600;
  font-size: 20px;
  color: #6c6b75;
  width: 280px;
}
@media screen and (max-width: 991px){
  .all-tc {
    background: #738392;
    height: 680px;
    font-family: "Flama Condensed";
  }
  .tc-top-text-title {
    width: 75%;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 35px;
    margin: 0 auto;
  }
  .tc-board-mobile {
    background-color: #fff;
    width: 90%;
    height: auto;
    border-radius: 12.03px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  .tc-board-disabled{
    display: none;
  }
  .tc-geometry {
    display: none;
  }
  .tc-all-content{
      display: inline-block;
      justify-content: center;
      margin-top: 10px;
      width:90%;
      height:90%;
  }
  .tc-all-top {
    display: flex;
    justify-content: center;
    width: 75%;
    height: 70%;
    margin: 0 auto;
  }
  .tc-top-image{
    margin-top: 10px;
  }
  .tc-top-title {
    font-weight: 700;
    font-size: 42.48px;
    color: #f46a63;
    margin-top: 5px;
    margin-left: 5px;
  }
  .tc-top-text {
    font-weight: 600;
    font-size: 16px;
    color: #6c6b75;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .tc-all-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70%;
    margin: 0 auto;
  }
  .tc-bottom-title {
    font-weight: 700;
    font-size: 48px;
    color: #f46a63;
    margin-top: 28px;
    margin-left: 20px;
    text-align: left;
  }
  .tc-bottom-image{
    margin-top: 25px;
    margin-left: 50px;
  }
  .tc-bottom-text {
    font-weight: 600;
    font-size: 16px;
    color: #6c6b75;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}