.loading {
  display: block; /* Esconde o loading por padrão */
  position: fixed; /* Fixa o loading na janela de visualização */
  z-index: 999; /* Faz o loading aparecer em cima de tudo */
  left: 0;
  top: 0;
  width: 100%; /* Largura total */
  height: 100%; /* Altura total */
  overflow: auto; /* Permite a rolagem se necessário */
  background-color: rgb(0, 0, 0); /* Fundo preto */
  background-color: rgba(0, 0, 0, 0.4); /* Fundo preto com opacidade */
}

.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #326582;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0 0 -60px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
