.question-component-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.question-component-container .value-title {
  width: 829px;
  border-radius: 110px;
  background: #2f4b7b;
  color: #fff;
  color: #fff;
  text-align: center;
  font-family: "Flama Condensed";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  padding: 13px;
}

.question-component-container .description {
  width: 80%;
  color: #2f4b7b;
  text-align: center;
  font-family: "Flama Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  margin-bottom: 40px;
}

.question-component-container .question-card {
  width: 80%;
  border: 2px solid #2f4b7b;
  border-radius: 12px 12px 0px 0px;
}

.question-component-container .question-card-title {
  background: #2f4b7b;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
  text-align: center;
  font-family: "Flama Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  margin-bottom: 20px;
}
@media screen and (max-width: 991px){
  .question-component-container .value-title {
    width: 90%;
    font-size: 16px;
    padding: 10px;
  }
  .question-component-container .description {
    width: 90%;
    font-size: 16px;
  }
  .question-component-container .question-card-title {
    font-size: 16px;
    padding: 7px;
  }
}