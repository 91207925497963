.all-top {
  font-family: "Flama Condensed";
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  justify-content: center;
  margin-top: 0;
}

.download {
  background-color: #738392;
  width: 100%;
  height: 185px;
}

.text-completed {
  color: #fff;
  padding-top: 22px;
  margin-bottom: 30px;
}
.results-top-button {
  margin-top: 45px;
}
.devolution {
  margin-top: 60px;
}
.text-devolution {
  color: #738392;
  width: 936px;
  margin: 0 auto;
}

.button-devolution {
  margin-top: 70px;
  width: 540px;
  height: 75px;
  background: #ffb800;
  color: #fbfdff;
  font-size: 30px;
}

button {
  flex-shrink: 0;
  border-radius: 759px;
  border: none;
  font-family: Flama Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.line {
  height: 2px;
  border: none;
  background-image: linear-gradient(to right, #f8b127, #9bbacc);
  justify-content: center;
  width: 459px;
  margin-top: 70px;
}
@media screen and (max-width: 991px){
  .all-top {
    font-family: "Flama Condensed";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    justify-content: center;
    margin-top: 0;
  }
  .download {
    background-color: #738392;
    width: 100%;
    height: 138px;
  }
  .text-completed {
    color: #fff;
    padding-top: 12px;
    margin-bottom: 25px;
  }
  .results-top-button {
    margin-top: 0px;
  }
  .devolution {
    margin-top: 20px;
  }
  .text-devolution {
    color: #738392;
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
  }
  
  .button-devolution {
    margin-top: 40px;
    width: 95%;
    height: 48px;
    background: #ffb800;
    color: #fbfdff;
    font-size: 19px;
  }
  
  button {
    flex-shrink: 0;
    border-radius: 759px;
    border: none;
    font-family: Flama Condensed;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  
  .line {
    height: 2px;
    border: none;
    background-image: linear-gradient(to right, #f8b127, #9bbacc);
    justify-content: center;
    width: 70%;
    margin-top: 50px;
  }
}
