.top-hwp-block{
    width:100%;
    height: 270px;
    background-color: #738392;
    font-family: "Flama Condensed";
}
.hwp-top-text-title{
font-size: 40px;
font-weight: 700;
color: #fff;
padding-top: 25px;
}
.hwp-top-text-text1{
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    padding-top: 20px;
}
.hwp-top-text-text2{
    font-size: 30px;
    font-weight: 300;
    color: #fff;
}
.all-pillars-hwp{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 70px;
    margin-top: -80px;
}
.bottom-hwp-center{
    width: 650px;
    margin: 0 auto;
    padding-top: 40px;
}
.bottom-hwp-text{
    font-weight: 400;
    font-size: 26px;
    color: #738392;
    width: 100%;
    text-align: center;
    padding-top: 50px;
}
.bottom-hwp-button{
    margin-top: 20px;
}
.bottom-hwp-button-button{
    background-color: #ffb800;
    width: 540.38px;
    height: 75px;
    border-radius: 1459.61px;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}
.bottom-hwp-back{
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #6d7e91;
    cursor: pointer;
}
.bottom-hwp-right{
display: flex;
justify-content: right;
margin-top: -200px;
}
@media screen and (max-width:1154px){
    .bottom-hwp-right{
        display: none;
        justify-content: right;
        margin-top: -200px;
        }
        .bottom-hwp-back{
            margin-top: 20px;
            font-weight: 600;
            font-size: 20px;
            color: #6d7e91;
            cursor: pointer;
            padding-bottom: 50px;
        }
}
@media screen and (max-width:991px){
    .top-hwp-block-mob{
        width:100%;
        height: 229px;
        background-color: #738392;
        font-family: "Flama Condensed";
    }
    .hwp-top-text-title-mob{
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    padding-top: 15px;
    }
    .hwp-top-text-text1-mob{
        font-size: 18px;
        font-weight: 300;
        color: #fff;
        padding-top: 15px;
        width: 62%;
        margin: 0 auto;
    }
    .hwp-top-text-text2-mob{
        font-size: 18px;
        font-weight: 300;
        color: #fff;
        padding-top: 10px;
        width: 62%;
        margin: 0 auto;
    }
    .all-pillars-hwp-mob{
        width: 100%;
        display: inline-block;
        margin: auto;
        gap: 70px;
        margin-top: -8%;
    }
    .bottom-hwp-center-mob{
        width: 100%;
        height: 215px;
        padding-top: 40px;
        background-color: #738392;
    }
    .bottom-hwp-text-mob{
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        width: 100%;
        text-align: center;
        padding-top: 20px;
        font-family: "Flama Condensed";
    }
    .bottom-hwp-button-mob{
        margin-top: 20px;
    }
    .bottom-hwp-button-button-mob{
        background-color: #ffb800;
        width: 90%;
        height: 48px;
        border-radius: 1459.61px;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
    }
    .bottom-hwp-back{
        margin-top: 20px;
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        font-family: "Flama Condensed";
    }
}
@media screen and (max-width:356px){
    .hwp-top-text-text2-mob{
        width: 72%;
    }
    .all-pillars-hwp-mob{
        width: 100%;
        display: inline-block;
        margin: auto;
        gap: 70px;
        margin-top: -10%;
    }
}