.small-button {
  width: 281px;
  height: 39px;
  flex-shrink: 0;
  border-radius: 759px;
  background: #6d7e91;
  border: none;
  color: #fff;
  font-family: Flama Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.big-button {
  width: 540px;
  height: 75px;
  border-radius: 1459.615px;
  background: #ffb800;
  color: #fbfdff;
  border: none;
  cursor: pointer;
  color: #fbfdff;
  font-family: Flama Condensed;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.small-button:hover {
  background: #d9d9d9;
  color: #6d7e91;
}
@media screen and (max-width:991px) {
  .big-button {
    width: 90%;
    height: 47px;
    font-size: 25px;
  }
}
