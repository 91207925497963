.all-svc{
    background-color: #738392;
    display: flex;
    margin-bottom: -4px;
    font-family: "Flama Condensed";
}
.svc-right{
width:50%;
padding-top: 175px;
margin:0 auto;
}
.svc-text{
    font-weight: 550;
    font-size: 25px;
    color: #fff;
}
.svc-button{
    margin-top:20px;
}
.svc-button-button{
    background-color: #ffb800;
    width: 98%;
    height: 75px;
    border-radius: 1459.61px;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}
@media screen and (max-width:991px){
    .left-svc{
        display: none;
        
    }
    .svc-right{
        display: inline-block;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 80%;
    }
    .svc-text{
        font-size: 18px;
    }

    .svc-button-button{
        width: 90%;
        height: 48px;
        border-radius: 934.57px;
        font-size: 25.61px;
    }
}