.all-ball-img{
  background-color: #fff;
  border-radius: 50%;
}
.ep-ball-img{
    width: 97%;
    height: 97%;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    margin: 0 auto;
  }
  .ep-only-img{
    z-index: 2;
    display: flex;
    justify-content: center; 
  }
  /* primeira imagem
      margin-top: -45px

    segunda imagem
       
    terceira imagem
    
    quarta imagem 
      margin-top -22px

    quinta imagem
      margin-top: 5px

    sexta imagem
      margin-top: 6px

*/
