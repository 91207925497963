.all-fthc{
    background-color: #738392;
    color: #fff;
    font-family: "Flama Condensed";
    height: 700px;
}
.fthc-top-text{
    width: 95%;
margin: 0 auto;
font-size: 40px;
font-weight: 700;
padding-top: 45px;
}
.fthc-bottom-text{
    width:884px;
margin: 0 auto;
font-size: 30px;
font-weight: 700;
padding-bottom: 30px;
}
.fthc-persons{
    display: flex;
    width: 97%;
    justify-content: left;
    overflow: scroll;
    margin-left: 2%;
}
.fthc-persons::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width: 991px){
    .fthc-top-text{
    width:97%;
    font-size: 25px;
    }
    .fthc-bottom-text{
        width:93%;
    font-size: 18px;
    }
    .fthc-persons{
        width: 90%;
        overflow: scroll;
        justify-content: 0;
        display: inline-block;
        margin-left: 20px;
    }
}