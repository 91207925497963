.all-pinfo{
  width: 100%;
  height: 100%;
  position: fixed;
}
.all-pinfo-shadow{
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
}
.all-pinfo-infos {
  width: 80%;
  height: 405px;
  border-radius: 24.42px;
  transform: translate(2%, -135%);
  box-shadow: 4.884735107421875px 7.3271026611328125px 6.105919361114502px 0px
    #00000073;
  background: linear-gradient(180deg, #2f4b7b 0%, #7489a1 100%);
  justify-content: center;
  margin: 0 auto;
  font-family: "Flama Condensed";
  display: flex;
  z-index: 3;
}
.pinfo-left {
  width: 350px;
  margin-left: 10px;
}
.pinfo-person-photo{
  margin-left: 20%;
  margin-top: 50px;
}
.pinfo-name {
  font-weight: 700;
  font-size: 39.08px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}
.pinfo-line-line {
    height: 301.63px;
    border: none;
    background-image: linear-gradient(to bottom, #f8b127, #9bbacc);
    width: 2px;
    margin-top: 50px;
}
.pinfo-text {
  width: 479.93px;
  height: 301.63px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: left;
}
.pi-close-x{
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}
@media screen and (max-width: 1066px) {
  .pinfo-text {
    width: 479.93px;
    height: 301.63px;
    margin: 0 auto;
    margin-top: 50px;
    margin-left: 5px;
    text-align: left;
  }
}
@media screen and (max-width:991px){
  .all-pinfo{
    width: 110%;
    height: 180%;
    position: fixed;
  }
  .all-pinfo-shadow{
    background: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
  }
  .all-pinfo-infos {
    width: 80%;
    height: 40%;
    border-radius: 24.42px;
    transform: translate(2%, -150%);;
    box-shadow: 4.884735107421875px 7.3271026611328125px 6.105919361114502px 0px
      #00000073;
    background: linear-gradient(180deg, #2f4b7b 0%, #7489a1 100%);
    justify-content: center;
    margin: 0 auto;
    font-family: "Flama Condensed";
    display: inline-block;
    z-index: 3;
    overflow: scroll;
  }
  .pinfo-left {
    width: 100%;
    margin-left: 0px;
  }
  .pinfo-person-photo{
   width: 100%;
   margin-top: 10px;
   margin-left: 0px;
   display: flex;
   justify-content: center;
  }
  .pinfo-name {
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    text-align: center;
    margin-top: 15px;
  }
  .pinfo-line-line {
      height: 2px;
      border: none;
      background-image: linear-gradient(to bottom, #f8b127, #9bbacc);
      width: 75%;
      margin-top: 20px;
      margin-left: 10%;
  }
  .pinfo-text {
    width: 75%;
    height: auto;
    margin: 0 auto;
    margin-top: 15px;
    text-align: left;
    margin-left: 10%;
    padding: 2px;
  }
  .pi-close-x{
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
  }
  .pi-close-x{
    top: 0%;
    right: 0%;
    cursor: pointer;
    position: absolute;
  }
  .arrow-down-pi{
    position: absolute;
    bottom: 0%;
    right: 3%;
  }
}
