.all-rworkshop {
    width: 100%;
    display: flex;
    justify-content: right;
    font-family: "Flama Condensed";
    margin-bottom: -6px;
  }
  .rworkshop-left{
      width: 392px;
  }
  .rworkshop-right{
      width:1048;
  }
  .rworkshop-content{
      width:489px;
      position: absolute;
      left: 8%;
      margin-top: 145px;
  }
  .rworkshop-first-text {
    width: 437px;
    margin: 0 auto;
    font-size: 32px;
    font-weight: 700;
    color: #738392;
  }
  .rworkshop-second-text{
      margin-top: 25px;
  }
  .rworkshop-button{
      margin-top: 70px;
  }
  @media screen and (max-width:991px){
    .all-rwp-mob {
        width: 100%;
        height: 345px;
        display: flex;
        justify-content: right;
      }
      .rwp-mob-left {
        background: linear-gradient(to right, white 0%, white 50%, rgba(255,255,255,0) 100%);;
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0%;
        height: 345px;
      }
      .rwp-mob-text1 {
        font-family: "Flama Condensed";
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        color: #738392;
        margin-top: 70px;
        width: 54%;
      }
      .rwp-mob-text2 {
        margin-top: 10px;
        margin-right: 45%;
      }
    .rwp-button{
        margin-right: 45%;
        margin-top: 50px;
    }
  }