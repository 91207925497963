.error-toast {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 180px;
  right: 90px;
  width: 350px;
  z-index: 9999;
  padding: 10px;
  background-color: #c03636;
  color: #fff;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.error-toast-message{
    margin-left: 10px;
}

.close-button {
    position: absolute; /* Adicionado */
    top: 2px; /* Ajuste a posição conforme necessário */
    right: 12px; /* Ajuste a posição conforme necessário */
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }