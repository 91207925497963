.all-step{
    border: 2px solid #738392;
    border-radius: 20px;
    width: 350px;
    height: 221px;
    text-align: center;
    font-family: "Flama Condensed";
}
.step-ball{
    width:52px;
    height: 52px;
    background-color: #738392;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -25px;
}
.step-number{
font-size: 40px;
font-weight: 800;
color:#fff;
}
.step-img{
    margin-top: 12px;
}
.step-title{
    font-size:20px;
    font-weight: 600;
    color:#6d7e91;
    margin-top: 5px;
}
.step-text{
    font-size: 18px;
    font-weight: 400;
    color:#6c6b75;
    padding-top: 4px;
    margin: 0 auto;
}
@media screen and (max-width:991px) {
    .all-step{
        width: 90%;
        margin: 0 auto;
    }
    .step-text{
        font-size: 15px;
    }
}
@media screen and (max-width:1114px){
    .all-step{
        border: 2px solid #738392;
        border-radius: 20px;
        width: 300px;
        height: 221px;
        text-align: center;
        font-family: "Flama Condensed";
    }
    .step-text{
        font-size: 15px;
        font-weight: 400;
        color:#6c6b75;
        padding-top: 4px;
        margin: 0 auto;
    }
}